import { defineMessages } from 'react-intl';

export default defineMessages({
  submitErrorTitle: {
    id: 'app.paymentMethod.bnplChallenge.submitError.title',
    defaultMessage: 'No pudimos confirmar el pago',
  },
  submitErrorDescription: {
    id: 'app.paymentMethod.bnplChallenge.submitError.description',
    defaultMessage: 'Por favor intenta nuevamente.',
  },
  submitErrorActionLabel: {
    id: 'app.paymentMethod.bnplChallenge.submitError.actionLabel',
    defaultMessage: 'Probar de nuevo',
  },
  getScreenErrorTitle: {
    id: 'app.paymentMethod.bnplChallenge.revamp.getScreenError.title',
    defaultMessage: 'Tuvimos un problema y lo estamos resolviendo',
  },
  getScreenErrorDescription: {
    id: 'app.paymentMethod.bnplChallenge.revamp.getScreenError.description',
    defaultMessage: 'Lo sentimos. Vuelve a intentar en unos minutos.',
  },
  getScreenErrorFirstCTALabel: {
    id: 'app.paymentMethod.bnplChallenge.revamp.getScreenError.firstCTALabel',
    defaultMessage: 'Reintentar',
  },
  getScreenErrorSecondCTALabel: {
    id: 'app.paymentMethod.bnplChallenge.revamp.getScreenError.secondCTALabel',
    defaultMessage: 'Volver',
  },
});
