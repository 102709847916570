/* eslint-disable no-unused-expressions */
import { useEffect } from 'react';
import { useIsWebviewIos } from './useDeviceInfo';

const removeSafeArea = {
  command: 'webview-event',
  event: 'removeSafeArea',
};

const restoreSafeArea = {
  command: 'webview-event',
  event: 'restoreSafeArea',
};

const publishEventsOnIos = (event) =>
  window.webkit?.messageHandlers?.ListenEventsInterface?.postMessage({
    ...event,
  });

export const useFullScreenEvent = () => {
  const isIosWebview = useIsWebviewIos();

  useEffect(() => {
    if (isIosWebview) {
      publishEventsOnIos(removeSafeArea);

      return () => {
        publishEventsOnIos(restoreSafeArea);
      };
    }
  }, [isIosWebview]);
};
