import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useFenix } from '@pedidosya/web-fenix/contexts/FenixContext';
import TopAppBar from '@pedidosya/web-fenix/system/TopAppBar';
import { useGoBack } from '@app/hooks/useGoBack';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import FixedHeader from '../../components/FixedHeader';
import { useTermsAndConditionsDetail } from '@app/services/queries';
import messages from './messages';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useShowDialog } from '@app/hooks/useShowDialog';
import { useQueryParamValue } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useHeaderPaddingTop } from '@app/hooks/useHeaderPaddingTop';
import ChangeBodyBackgroundColor from '@app/components/ChangeBodyBackgroundColor';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: unset;
`;

export const StyledTopAppBar = styled(TopAppBar)`
  height: auto;
  padding-top: ${({ theme, paddingTop }) => `${theme.space(paddingTop)} !important`};
`;

const RevampTopAppBar = ({ title, onGoBack }) => {
  const { paddingTop } = useHeaderPaddingTop();
  return (
    <StyledTopAppBar
      paddingTop={paddingTop}
      onGoBack={onGoBack}
      left="icon"
      middle="title"
      titleValue={title}
      right="empty"
    />
  );
};

const FenixHeader = ({ title, onGoBack }) => (
  <FixedHeader
    action={
      <span role="button" tabIndex={0} title="close" onClick={onGoBack}>
        <ChevronLeft size="medium" />
      </span>
    }
  >
    {title}
  </FixedHeader>
);

function TermsAndConditionsDetail() {
  const {
    config: { revamp },
  } = useFenix();
  const { formatMessage } = useIntl();
  const { goBack } = useGoBack();
  const url = useQueryParamValue(QUERY_PARAM.URL);
  const { showDialog: showErrorDialog } = useShowDialog();

  const { data, isLoading, isError } = useTermsAndConditionsDetail(url);
  const [iframeLoading, setIframeLoading] = useState(isLoading);

  useEffect(() => {
    if (isError) {
      setIframeLoading(false);
      const dismissErrorDialogAction = {
        type: BFF_ACTION_TYPES.DISMISS_DIALOG,
      };
      const goBackAction = {
        type: BFF_ACTION_TYPES.NAVIGATE_BACK,
      };
      showErrorDialog({
        fenixLayout: true,
        title: formatMessage(messages.errorTitle),
        description: formatMessage(messages.errorSubtitle),
        primary_action: {
          label: formatMessage(messages.errorActionLabel),
          actions: [dismissErrorDialogAction, goBackAction],
        },
      });
    }
  }, [isError]);

  const Header = revamp ? RevampTopAppBar : FenixHeader;

  return (
    <Container>
      <ChangeBodyBackgroundColor color="white" />
      <Header title={formatMessage(messages.header)} onGoBack={goBack} />
      {data && !isError ? (
        <Iframe
          title="Terms And Conditions Detail"
          src={url}
          onLoadCapture={() => setIframeLoading(false)}
        />
      ) : null}
      {(isLoading && !isError) || iframeLoading ? <PeyaLoader position="center" /> : null}
    </Container>
  );
}

export default TermsAndConditionsDetail;
