import React from 'react';
import PropTypes from 'prop-types';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import FeedbackPage from '@pedidosya/web-fenix/system/FeedbackPage';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import PaymentsGlobalStyleSheet from '@app/theme/PaymentsGlobalStyleSheet';
import { BrandError, theme } from '@appscore/web-components';
import GlobalStyleSheet from '@appscore/web-components/theme/globalStyleSheet';
import { ThemeProvider } from 'styled-components';
import { GO_BACK } from '../../constants/routes';
import logger from '../../logger';
import ChangeBodyBackgroundColor from '../ChangeBodyBackgroundColor';

const LoadAppError = ({ error, enableRevamp }) => {
  const hasHistory = window?.history?.length > 1;
  const secondaryLabel = hasHistory ? 'Volver' : null;
  const onSecondaryAction = hasHistory
    ? () => {
        window.history.go(GO_BACK);
      }
    : null;
  logger.error('[LoadAppError] error: ', error?.message);
  if (enableRevamp) {
    return (
      <FenixProvider config={{ revamp: enableRevamp }}>
        <ChangeBodyBackgroundColor color="white" />
        <FeedbackPage
          data-testid="feedback-page"
          showBack={Boolean(onSecondaryAction)}
          title="Tuvimos un problema y lo estamos resolviendo"
          description="Lo sentimos. Vuelve a intentar en unos minutos."
          variant="error"
        >
          <FeedbackPage.TopBar
            left="icon"
            middle="title"
            right="empty"
            onGoBack={onSecondaryAction}
          />
          <FeedbackPage.FooterButton>
            <FooterButton.Button
              fullWidth
              hierarchy="primary"
              label="Intentar nuevamente"
              onClick={() => window.location.reload()}
              size="large"
              state="enabled"
            />
            {Boolean(onSecondaryAction) ? (
              <FooterButton.Button
                fullWidth
                hierarchy="secondary"
                label={secondaryLabel}
                onClick={onSecondaryAction}
                size="large"
                state="enabled"
              />
            ) : null}
          </FeedbackPage.FooterButton>
        </FeedbackPage>
      </FenixProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <>
        <PaymentsGlobalStyleSheet />
        <GlobalStyleSheet />
        <BrandError
          primaryLabel="Intentar nuevamente"
          onPrimaryAction={() => window.location.reload()}
          secondaryLabel={secondaryLabel}
          onSecondaryAction={onSecondaryAction}
        />
      </>
    </ThemeProvider>
  );
};
LoadAppError.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default LoadAppError;
