import * as Sentry from '@sentry/react';
import pkg from '../../../package.json';
import { PROD_ENVIRONMENT } from '@commons/constants/environments';
import { SENTRY_CONFIG } from './constants';

let sentryHubInstance;

export const initSentry = ({ environment }) => {
  try {
    const sampleRate = environment && environment === PROD_ENVIRONMENT ? 0.1 : 1;
    const {
      location: { pathname },
    } = window;

    Sentry.init({
      dsn: SENTRY_CONFIG.dsn,
      integrations: [Sentry.browserTracingIntegration()],
      environment,
      release: `${pkg.name}@${pkg.version}`,
      tracesSampleRate: sampleRate,
      sampleRate,
    });
    return Sentry;
  } catch (error) {
    // Log to console.error because at this point the Sentry instance did not work
    console.error(error);
    throw error;
  }
};

export default sentryHubInstance;
