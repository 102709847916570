export const SENTRY_CONFIG = {
  dsn: 'https://50ed2115551a60caf229f4aef4665643@o4504046939799552.ingest.us.sentry.io/4507222416490496',
};

export const SENTRY_LOG_LEVEL = {
  debug: 'debug',
  info: 'info',
  warn: 'warning',
  warning: 'warning',
  error: 'error',
  fatal: 'fatal',
};
