import { isStaging, isProduction } from '../utils/environment';
import { isObject } from '../utils/object';

import { initializeSentry, logEvent, logError } from '../monitoring/sentry';
import getSentryInstance from '../monitoring/sentry/instance';
import { SENTRY_LOG_LEVEL } from '../monitoring/sentry/constants';

import getDataDogInstance from '../monitoring/datadog/logger/instance';
import { initDataDogLogger, DATADOG_LOG_LEVEL } from '../monitoring/datadog/logger/datadog-logger';

const DATADOG_LOGGER = true;
const SENTRY_LOGGER = true;

const noopLogger = {
  trace: () => {},
  debug: () => {},
  info: () => {},
  warn: () => {},
  warning: () => {},
  error: () => {},
  fatal: () => {},
};

let instance = {
  // eslint-disable-next-line no-console
  trace: console.trace,
  // eslint-disable-next-line no-console
  debug: console.debug,
  info: console.info,
  warn: console.warn,
  warning: console.warn,
  error: console.error,
  fatal: (error, data) => console.error(error?.message),
};

if (process.env.NODE_ENV === 'test') {
  instance = { ...noopLogger };
}

const logToDataDog = (level, context, args) => {
  const ddInstance = getDataDogInstance();
  if (ddInstance) {
    if (context) {
      ddInstance[DATADOG_LOG_LEVEL[level]](args.join(' '), { ...context });
    } else {
      ddInstance[DATADOG_LOG_LEVEL[level]](args.join(' '));
    }
  }
};

const logToSentry = (level, context = {}, args) => {
  const sentryInstance = getSentryInstance();
  if (!sentryInstance) return;
  if (SENTRY_LOG_LEVEL[level] === SENTRY_LOG_LEVEL.fatal) {
    const error = context?.error;
    delete context?.error;
    logError(error, context);
  } else {
    const eventName = args.shift();
    logEvent(eventName, {
      level: SENTRY_LOG_LEVEL[level],
      data: {
        eventDescription: args.join(' '),
        ...context,
      },
    });
  }
};

const logWithJoinedMessageAndContext = (level, ...args) => {
  const lastArg = args[args.length - 1];
  let context;
  if (isObject(lastArg)) {
    context = args.pop();
  }
  if (DATADOG_LOGGER) {
    logToDataDog(level, context, args);
  }
  if (SENTRY_LOGGER) {
    logToSentry(level, context, args);
  }
};

export const initClientLogger = ({
  country,
  environment,
  deviceInfo,
  userId,
  purchaseId,
  checkoutId,
  requestId,
} = {}) => {
  if (process.env.NODE_ENV === 'test') return noopLogger;
  if ((!isStaging() && !isProduction()) || (!SENTRY_LOGGER && !DATADOG_LOGGER)) return instance;

  if (SENTRY_LOGGER)
    initializeSentry({
      country,
      environment,
      deviceInfo,
      userId,
      purchaseId,
      checkoutId,
      requestId,
    });
  if (DATADOG_LOGGER)
    initDataDogLogger({
      country,
      environment,
      deviceInfo,
      userId,
      purchaseId,
      checkoutId,
      requestId,
    });

  instance.debug = (...args) => logWithJoinedMessageAndContext('debug', ...args);
  instance.info = (...args) => logWithJoinedMessageAndContext('info', ...args);
  instance.warn = (...args) => logWithJoinedMessageAndContext('warn', ...args);
  instance.warning = (...args) => logWithJoinedMessageAndContext('warning', ...args);
  instance.error = (...args) => logWithJoinedMessageAndContext('error', ...args);
  instance.fatal = (error, data) =>
    logWithJoinedMessageAndContext('fatal', error?.message, {
      errorMessage: error?.message,
      errorStack: error?.stack,
      error,
      ...data,
    });

  return instance;
};

export default instance;
