import React from 'react';
import styled from 'styled-components';
import TopAppBar from '@pedidosya/web-fenix/system/TopAppBar';
import Skeleton from '@pedidosya/web-fenix/system/Skeleton';
import { useGoBack } from '@app/hooks/useGoBack';
import { useHeaderPaddingTop } from '@app/hooks/useHeaderPaddingTop';
import ChangeBodyBackgroundColor from '@app/components/ChangeBodyBackgroundColor';
import { useFooterBottomOffset } from '@app/hooks/useFooterBottomOffset';

const MainContainer = styled.main`
  position: relative;
  height: 100vh;
`;

const HeaderBackgroundSkeleton = styled(Skeleton)`
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
`;

const StyledTopAppBar = styled(TopAppBar)`
  position: absolute;
  top: ${({ theme, paddingTop }) => theme.space(paddingTop)};
  left: 0;
  width: 100%;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.space('spacing-layout-xlarge')} ${theme.space('spacing-layout-medium')}`};
  gap: ${({ theme }) => theme.space('spacing-component-3xlarge')};
`;

const TwoSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space('spacing-layout-3xlarge')};
`;

const Skeleton1 = styled(Skeleton)`
  flex: 2;
`;

const Skeleton2 = styled(Skeleton)`
  flex: 1;
`;

const FooterContainer = styled.div`
  position: fixed;
  bottom: ${({ paddingFooter }) => (paddingFooter ? paddingFooter : '0')};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space('layout-margin-mobile')};
  background-color: ${({ theme }) => theme.color('shape-color-background-primary')};
  gap: ${({ theme }) => theme.space('spacing-layout-small')};
`;

const SkeletonContainer = () => {
  const { goBack } = useGoBack();
  const { paddingTop } = useHeaderPaddingTop();
  const { paddingFooter } = useFooterBottomOffset();

  return (
    <>
      <ChangeBodyBackgroundColor color="white" />
      <MainContainer>
        <HeaderBackgroundSkeleton height="shape-size-action-46" borderRadiusSize="large" />
        <StyledTopAppBar
          paddingTop={paddingTop}
          left="icon"
          middle="title"
          right="empty"
          onGoBack={() => goBack()}
        />
        <MainContent>
          <Skeleton height="shape-size-action-06" borderRadiusSize="large" />
          <Skeleton height="shape-size-action-06" borderRadiusSize="large" />
          <TwoSkeleton>
            <Skeleton1 height="shape-size-action-06" borderRadiusSize="large" />
            <Skeleton2 height="shape-size-action-06" borderRadiusSize="large" />
          </TwoSkeleton>
          <Skeleton height="shape-size-action-06" borderRadiusSize="large" />
        </MainContent>
        <FooterContainer data-testid="footer-container" paddingFooter={paddingFooter}>
          <Skeleton height="shape-size-action-12" borderRadiusSize="full" />
          <Skeleton height="shape-size-action-12" borderRadiusSize="full" />
        </FooterContainer>
      </MainContainer>
    </>
  );
};

export default SkeletonContainer;
