import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useGoBack } from '@app/hooks/useGoBack';
import logger from '@app/logger';
import { updateTermsAndConditionsStatus } from '@app/providers/mainContext/actions/updateTermsAndConditionsStatus';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';
import { useAppTermsAndConditionsStatus } from '@app/providers/mainContext/selectors/useAppTermsAndConditionsStatus';
import { useSaveChallenge } from '@app/services/mutations';
import { useChallengeScreen } from '@app/services/queries';
import { useQueryParamValue } from '@app/utils/domRouter';
import {
  BackendDrivenComponent,
  useBFFActionsExecutor,
  useBFFNavigate,
  useOnAppearActions,
} from '@checkout-ui/backend-driven';
import { QUERY_PARAM } from '@commons/constants';
import stringify from '@commons/utils/object/stringify';
import messages from './messages';
import { default as styled, useTheme } from 'styled-components';
import ChangeBodyBackgroundColor from '@app/components/ChangeBodyBackgroundColor';

import HeaderBackground from '@pedidosya/web-fenix/system/HeaderBackground';
import TopAppBar from '@pedidosya/web-fenix/system/TopAppBar';
import RowInteractiveL from '@pedidosya/web-fenix/system/RowInteractiveL';
import ListWithTitle from './components/ListWithTitle';
import RowInformativeL from '@pedidosya/web-fenix/system/RowInformativeL';
import FenixIcon from '@pedidosya/web-fenix/icons/FenixIcon';
import ImageContainerPayment from '@pedidosya/web-fenix/system/ImageContainerPayment';
import Footer from '@pedidosya/web-fenix/system/Footer';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import { useHeaderPaddingTop } from '@app/hooks/useHeaderPaddingTop';
import { useFooterBottomOffset } from '@app/hooks/useFooterBottomOffset';
import SkeletonContainer from './components/Skeleton/SkeletonContainer';
import { useFullScreenEvent } from '@app/hooks/useFullScreenEvent';
import { BrandErrorContainer } from '@app/components/Error';
import { useHasNotch } from '@app/hooks/useHasNotch';

const CHALLENGE_TYPE_BNPL = 'BNPL';
const IOS_STATUS_BAR_HEIGHT = 'spacing-layout-6xlarge';

const StyledBackendDrivenComponent = styled(BackendDrivenComponent)`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
`;

const StyledHeaderBackground = styled(HeaderBackground)`
  padding-top: ${({ theme, paddingTop }) => theme.space(paddingTop)};
`;

export const StyledTopAppBar = styled(TopAppBar)`
  height: auto;
  padding-top: ${({ theme, paddingTop }) => `${theme.space(paddingTop)} !important`};
`;

const FooterStyled = styled(Footer)`
  position: sticky;
  padding-bottom: ${({ paddingFooter }) => paddingFooter};
`;

const RevampBuyNowPayLaterChallenge = () => {
  const [errorSubmit, setErrorSubmit] = useState(false);
  const { navigate } = useBFFNavigate();
  const { formatMessage } = useIntl();
  const challengeId = useQueryParamValue(QUERY_PARAM.CHALLENGE_ID);
  const {
    data,
    error,
    isLoading: isLoadingScreen,
    refetch,
  } = useChallengeScreen(CHALLENGE_TYPE_BNPL, challengeId);
  const {
    isLoading: submittingChallenge,
    isSuccess: submitSuccessChallenge,
    mutate: submitChallenge,
  } = useSaveChallenge(CHALLENGE_TYPE_BNPL);
  const { goBack } = useGoBack();
  const dispatch = useAppDispatch();
  const { areTermsAccepted } = useAppTermsAndConditionsStatus();
  const { paddingTop } = useHeaderPaddingTop();
  const { paddingFooter } = useFooterBottomOffset();
  const { hasNotch } = useHasNotch();
  const fenixTheme = useTheme();

  useFullScreenEvent();

  const actions = isLoadingScreen ? [] : data?.on_appear?.actions;
  useOnAppearActions(actions);

  const onSubmit = (payload) => {
    if (submittingChallenge || submitSuccessChallenge) return;
    logger.info(
      '[REVAMP_BNPL_CHALLENGE][BNPL_SUBMIT_INTENT]',
      'Trying to submit BNPL',
      stringify(payload),
    );
    submitChallenge(
      { ...payload },
      {
        onSuccess: (data) => {
          const actions = data?.actions;
          if (actions?.length) {
            logger.info(
              '[REVAMP_BNPL_CHALLENGE][BNPL_SUBMIT_SUCCESS]',
              'BNPL was submitted successfully',
              stringify(actions),
            );
            executeBFFActions(actions);
          } else {
            logger.error(
              '[REVAMP_BNPL_CHALLENGE][BNPL_NO_SUBMIT_ACTION]',
              'Submit BNPL response does not have actions',
            );
            setErrorSubmit(true);
          }
        },
        onError: (error) => {
          logger.error(
            '[REVAMP_BNPL_CHALLENGE][BNPL_SUBMIT_ERROR]',
            `Failed submitting challenge with action: ${payload?.action}. Error: `,
            error.message,
          );
          if (payload?.action === 'CANCELLED' || payload?.action === 'NAVIGATE_BACK')
            executeBFFActions([{ type: 'NAVIGATE_BACK' }]);
          else setErrorSubmit(true);
        },
      },
    );
  };

  const actionDefinitions = {
    SUBMIT_BNPL_CHALLENGE: onSubmit,
    [BFF_ACTION_TYPES.NAVIGATE]: (payload) => {
      const targetScreen = payload?.native_page?.name;
      if (targetScreen === 'TERMS_AND_CONDITIONS_DETAIL') navigate(payload);
      // NOTE: replace navigation in order to prevent user going back to previous submitted challenges
      else navigate(payload, { replace: true });
    },
  };

  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const componentResolvers = {
    HEADER_BACKGROUND: ({ data: { background, actions, components } }) => ({
      component: StyledHeaderBackground,
      props: {
        'data-testid': 'header-background',
        showSlots: true,
        backgroundImage: background,
        marginTopSlot1: hasNotch ? undefined : fenixTheme.space(IOS_STATUS_BAR_HEIGHT),
        topAppBar: (
          <StyledTopAppBar
            paddingTop={paddingTop}
            onGoBack={() => executeBFFActions(actions)}
            variant="oncontent"
            left="icon"
            middle="title"
            right="empty"
          />
        ),
        slot1: (
          <BackendDrivenComponent componentResolvers={componentResolvers} components={components} />
        ),
      },
    }),
    LIST: ({ data: { title, description } }) => ({
      component: ListWithTitle,
      props: {
        title,
        description,
        marginTopContent: 'spacing-layout-xsmall',
      },
    }),
    ROW_INFORMATIVE_L: ({
      data: {
        label,
        primary_left_text: primaryLeftText,
        primary_left_text_type: primaryLeftTextType,
        secondary_left_text: secondaryLeftText,
        tag,
        primary_right_text: primaryRightText,
        primary_right_text_type: primaryRightTextType,
      },
    }) => ({
      component: RowInformativeL,
      props: {
        label,
        primaryLeftText,
        primaryLeftTextType,
        secondaryLeftText,
        tag: tag?.text,
        tagType: tag?.category,
        primaryRightText,
        primaryRightTextType,
      },
    }),
    ICON: ({ data: { token, size } }) => ({
      component: FenixIcon,
      props: {
        token,
        size,
      },
    }),
    IMAGE_CONTAINER_PAYMENT: ({ data: { src, variant, size, alt } }) => ({
      component: ImageContainerPayment,
      props: {
        src,
        variant,
        size,
        imageAlt: alt,
      },
    }),
    ROW_INTERACTIVE_L: ({
      data: {
        primary_left_text: primaryLeftText,
        cta_type: ctaType,
        cta_subtype: ctaSubtype,
        primary_text_actions: primaryTextActions,
      },
    }) => ({
      component: RowInteractiveL,
      props: {
        primaryLeftText,
        ctaType,
        ctaSubtype,
        onClickPrimaryText: () => executeBFFActions(primaryTextActions),
        onClickRow: () => updateTermsAndConditionsStatus(dispatch, !areTermsAccepted),
        ctaBooleanValue: areTermsAccepted,
      },
    }),
    FOOTER: ({ data: { footer_type: footerType } }) => ({
      component: FooterStyled,
      props: {
        paddingFooter,
        footerType: (
          <BackendDrivenComponent
            componentResolvers={componentResolvers}
            components={[footerType]}
          />
        ),
        isShadowVisible: false,
        isSticky: true,
      },
    }),
    FOOTER_BUTTON: () => ({
      component: ({ children }) => <FooterButton>{children.props.children}</FooterButton>,
    }),
    BUTTON: ({ data: { label, full_width: fullWidth, hierarchy, size, actions } }) => {
      const isDisabled = hierarchy === 'primary' && !areTermsAccepted;
      const isLoading = submittingChallenge || submitSuccessChallenge;
      return {
        component: FooterButton.Button,
        props: {
          label,
          fullWidth,
          hierarchy,
          size,
          onClick: () => executeBFFActions(actions),
          state: isDisabled ? 'disabled' : isLoading ? 'loading' : 'enabled',
          disabled: isDisabled,
        },
      };
    },
  };

  if (error || errorSubmit) {
    if (error)
      logger.error('[RevampBuyNowPayLaterChallenge][GET_SCREEN][ERROR]', JSON.stringify(error));
    return (
      <BrandErrorContainer
        title={formatMessage(messages.getScreenErrorTitle)}
        description={formatMessage(messages.getScreenErrorDescription)}
        onPrimaryAction={() => (errorSubmit ? setErrorSubmit(false) : refetch())}
        primaryLabel={formatMessage(messages.getScreenErrorFirstCTALabel)}
        onSecondaryAction={() => (errorSubmit ? setErrorSubmit(false) : goBack())}
        secondaryLabel={formatMessage(messages.getScreenErrorSecondCTALabel)}
        errorCode={error ? error.response?.status : undefined}
      />
    );
  }

  if (isLoadingScreen) return <SkeletonContainer />;

  const components = data?.components;

  return (
    <Container>
      <ChangeBodyBackgroundColor color="white" />
      <StyledBackendDrivenComponent
        componentResolvers={componentResolvers}
        components={components}
      />
    </Container>
  );
};

export default RevampBuyNowPayLaterChallenge;
