import getInstance from './instance';

export default function addDataToScope(data, sentry) {
  const instance = sentry || getInstance();

  data.forEach(({ key, value }) => {
    if (value) {
      instance.setTag(key, value);
    }
  });
}
