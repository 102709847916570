import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BrandError } from '@appscore/web-components';
import Error from '@pedidosya/web-fenix/pages/Error';
import { useFenix } from '@pedidosya/web-fenix/contexts/FenixContext';
import FeedbackPage from '@pedidosya/web-fenix/system/FeedbackPage';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import isBadRequestStatus from '@app/utils/axios/isBadRequestStatus';
import { ErrorContainer } from './components/ErrorContainer';
import { useHeaderPaddingTop } from '@app/hooks/useHeaderPaddingTop';
import { useFooterBottomOffset } from '@app/hooks/useFooterBottomOffset';
import ChangeBodyBackgroundColor from '../ChangeBodyBackgroundColor';

const StyledFeedbackPage = styled(FeedbackPage)`
  padding-top: ${({ theme, paddingTop }) => theme.space(paddingTop)};
  padding-bottom: ${({ paddingFooter }) => paddingFooter};
`;

const BrandErrorContainer = ({
  title = 'Tuvimos un problema y lo estamos resolviendo',
  description = 'Lo sentimos. Vuelve a intentar en unos minutos.',
  onPrimaryAction,
  onSecondaryAction,
  primaryLabel = 'Reintentar',
  secondaryLabel = 'Volver',
  fenixLayout,
  ...rest
}) => {
  const {
    config: { revamp },
  } = useFenix();
  const { paddingTop } = useHeaderPaddingTop();
  const { paddingFooter } = useFooterBottomOffset();

  const { errorCode } = rest;
  const [actionProps, setActionProps] = useState({
    primaryActionClick: onPrimaryAction,
    primaryActionLabel: primaryLabel,
    secondaryActionClick: onSecondaryAction,
    secondaryActionLabel: secondaryLabel,
  });

  useEffect(() => {
    const { primaryActionClick, secondaryActionLabel, secondaryActionClick } = actionProps;

    if (!primaryActionClick || isBadRequestStatus(errorCode)) {
      if (secondaryActionClick) {
        setActionProps((prevState) => {
          const newState = {
            ...prevState,
            primaryActionLabel: secondaryActionLabel,
            primaryActionClick: secondaryActionClick,
          };
          delete newState.secondaryActionLabel;
          delete newState.secondaryActionClick;
          return newState;
        });
      }
    }
  }, [actionProps, errorCode]);

  if (revamp) {
    const { primaryActionLabel, primaryActionClick, secondaryActionLabel, secondaryActionClick } =
      actionProps;
    return (
      <>
        <ChangeBodyBackgroundColor color="white" />
        <StyledFeedbackPage
          showBack={Boolean(secondaryActionClick)}
          title={title}
          description={description}
          variant="error"
          paddingTop={paddingTop}
          paddingFooter={paddingFooter}
          {...rest}
        >
          <FeedbackPage.TopBar
            right="empty"
            left="icon"
            middle="title"
            onGoBack={secondaryActionClick}
          />
          <FeedbackPage.FooterButton>
            {Boolean(primaryActionClick) ? (
              <FooterButton.Button
                fullWidth
                hierarchy="primary"
                label={primaryActionLabel}
                onClick={primaryActionClick}
                size="large"
                state="enabled"
              />
            ) : null}
            {Boolean(secondaryActionClick) ? (
              <FooterButton.Button
                fullWidth
                hierarchy="secondary"
                label={secondaryActionLabel}
                onClick={secondaryActionClick}
                size="large"
                state="enabled"
              />
            ) : null}
          </FeedbackPage.FooterButton>
        </StyledFeedbackPage>
      </>
    );
  }

  if (fenixLayout) {
    return <Error {...rest} {...actionProps} />;
  }

  return (
    <ErrorContainer>
      <BrandError
        onPrimaryAction={onPrimaryAction}
        onSecondaryAction={onSecondaryAction}
        primaryLabel={primaryLabel}
        secondaryLabel={secondaryLabel}
        {...rest}
      />
    </ErrorContainer>
  );
};

export default BrandErrorContainer;
