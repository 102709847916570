import { GlobalHarmonizedFonts } from '@appscore/web-components/theme/Harmonization/index';
import GlobalStyleSheet from '@appscore/web-components/theme/globalStyleSheet';
import { getBasename, setBasenamePrefix } from '@commons/services/routes.service';
import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import MainRouter from './MainRouter';
import BackendDrivenDialog from './components/BackendDrivenDialog';
import BackendDrivenTooltip from './components/BackendDrivenTooltip';
import { BrandErrorBoundaryContainer } from './components/Error';
import ErrorBoundary from './components/ErrorBoundary';
import { createIntlProvider, getMessages } from './providers/IntlProvider';
import {
  PublicEnvProvider,
  getPublicEnv,
  publicEnvShape,
  usePublicEnv,
} from './providers/PublicEnv';
import { AppProviderWithResources } from './providers/mainContext/providerWithResources';
import ReactQueryProvider from './providers/reactQuery/ReactQueryProvider';
import { FwfFlagsProvider } from './providers/funWithFlags';
import { addPrefixToUrls } from './services/constants';
import { theme } from './theme';
import PaymentsGlobalStyleSheet from './theme/PaymentsGlobalStyleSheet';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({ trackerPromise, fwfPromise, ...customProps }) => {
  const { appShellBasePath, enableRevamp } = customProps;
  setBasenamePrefix(appShellBasePath);
  if (appShellBasePath) {
    addPrefixToUrls(appShellBasePath);
  }

  return (
    <PublicEnvProvider appShellBasePath={appShellBasePath} getPublicEnv={getPublicEnv}>
      <IntlProvider>
        <Router basename={getBasename()}>
          <AppProviderWithResources trackerPromise={trackerPromise}>
            <ReactQueryProvider>
              <FenixProvider config={{ revamp: enableRevamp }}>
                <ThemeProvider theme={theme}>
                  <FwfFlagsProvider fwfPromise={fwfPromise}>
                    <PaymentsGlobalStyleSheet />
                    <GlobalStyleSheet />
                    <GlobalHarmonizedFonts />
                    <ErrorBoundary fallback={<BrandErrorBoundaryContainer />}>
                      <MainRouter />
                      <BackendDrivenDialog />
                      <BackendDrivenTooltip />
                    </ErrorBoundary>
                  </FwfFlagsProvider>
                </ThemeProvider>
              </FenixProvider>
            </ReactQueryProvider>
          </AppProviderWithResources>
        </Router>
      </IntlProvider>
    </PublicEnvProvider>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default Root;
