import React, { Children } from 'react';
import List from '@pedidosya/web-fenix/system/List';
import styled from 'styled-components';

export const Container = styled(List)`
  padding-top: ${({ theme }) => theme.space('spacing-layout-small')};
`;

export const ListWithTitle = ({ title, description, marginTopContent, children }) => {
  return (
    <Container type="default" marginTopContent={marginTopContent}>
      <List.Title titleValue={title} descriptionValue={description} />
      {Children.map(children, (child) => (
        <List.Row>{child}</List.Row>
      ))}
    </Container>
  );
};
