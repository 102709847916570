import React from 'react';
import PropTypes from 'prop-types';
import Header from '@pedidosya/web-fenix/system/Header';

const RevampedHeader = ({ actions, executeBFFActions }) => {
  return (
    <Header>
      <Header.TopBar left="icon" middle="title" onClose={() => executeBFFActions(actions)} />
    </Header>
  );
};

RevampedHeader.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
};

export default RevampedHeader;
