import { isProduction } from '../../utils/environment';
import getInstance, { canLog, setInstance, setLogLevel } from './instance';
import { initSentry } from './initialize';
import addDataToScope from './addDataToScope';
import { SENTRY_LOG_LEVEL } from './constants';

let buffer = [];

function onSentryInitialized() {
  buffer.forEach(({ error, data, message }) => {
    if (error) logError(error, data);
    else logEvent(message, data);
  });
  buffer = [];
}

export function initializeSentry({
  country,
  environment,
  deviceInfo,
  userId,
  purchaseId,
  checkoutId,
  requestId,
}) {
  const instance = initSentry({ environment });
  setInstance(instance);
  const dataToScope = [
    { key: 'country', value: country?.name },
    { key: 'platform', value: deviceInfo?.isWebview ? 'webview' : 'web' },
    { key: 'os', value: deviceInfo?.os },
    { key: 'deviceType', value: deviceInfo?.type },
    { key: 'userId', value: userId },
    { key: 'purchaseId', value: purchaseId },
    { key: 'checkoutId', value: checkoutId },
    { key: 'requestId', value: requestId },
  ];
  addDataToScope(dataToScope, instance);
  const logLevelSentry = isProduction() ? SENTRY_LOG_LEVEL.error : SENTRY_LOG_LEVEL.debug;
  setLogLevel(logLevelSentry);
  onSentryInitialized();
}

export function logError(error, data) {
  if (canLog(SENTRY_LOG_LEVEL.error)) {
    const instance = getInstance();
    if (instance) {
      instance.captureException(error, {
        extra: data,
      });
    } else {
      buffer.push({ error, data });
    }
  }
}

// We use prefix, to avoid confusing events that aren't from live env.
export function logEvent(event, { data, level = SENTRY_LOG_LEVEL.info } = {}) {
  if (canLog(level)) {
    const instance = getInstance();
    const prefix = isProduction() ? '' : '[STG]';
    const message = `${prefix}${event}`;
    if (instance) {
      instance.captureEvent({
        message,
        level,
        extra: data,
      });
    } else {
      buffer.push({ error: false, data, message });
    }
  }
}
