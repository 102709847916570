import { useEffect, useState } from 'react';
import { useIsWebviewIos } from './useDeviceInfo';
import { useWindow } from '@checkout-ui/backend-driven';

const MIN_WIDTH_NOTCH = 375;
const MIN_HEIGHT_NOTCH = 800;

export const useHasNotch = () => {
  const [hasNotch, setHasNotch] = useState(false);
  const isIosWebview = useIsWebviewIos();
  const w = useWindow();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isIosWithNotch =
        isIosWebview && w.screen?.width >= MIN_WIDTH_NOTCH && w.screen?.height >= MIN_HEIGHT_NOTCH;
      const screenHeight = w.screen?.height;
      const bodyHeight = w.document?.body?.offsetHeight;
      const hasAlreadyPadding = screenHeight > bodyHeight;
      setHasNotch(isIosWithNotch && !hasAlreadyPadding);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { hasNotch };
};
