import React, { useEffect, useState } from 'react';
import { useHasNotch } from './useHasNotch';

const FOOTER_PADDING_BOTTOM = 34;

export const useFooterBottomOffset = () => {
  // Add bottom offset in order to show footer when OSK appears on Safari
  // Source: https://tkte.ch/2019/09/23/iOS-VisualViewport.html
  const [bottomOffset, setBottomOffset] = useState('0px');
  const [paddingFooter, setPaddingFooter] = useState();
  const { hasNotch } = useHasNotch();

  useEffect(() => {
    if (hasNotch) setPaddingFooter(`${FOOTER_PADDING_BOTTOM}px`);
    else setPaddingFooter(undefined);
  }, [hasNotch]);

  useEffect(() => {
    let pendingUpdate = false;

    const viewportHandler = (event) => {
      if (pendingUpdate) return;
      pendingUpdate = true;

      requestAnimationFrame(() => {
        pendingUpdate = false;
        const offset = window.innerHeight - event.target.height;
        setBottomOffset(`${offset}px`);
        if (offset === 0 && hasNotch) setPaddingFooter(`${FOOTER_PADDING_BOTTOM}px`);
        else setPaddingFooter(undefined);
      });
    };

    // Check Visual Viewport API is supported
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', viewportHandler);
      window.visualViewport.addEventListener('scroll', viewportHandler);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', viewportHandler);
        window.visualViewport.removeEventListener('scroll', viewportHandler);
      }
    };
  }, [hasNotch]);

  return { bottomOffset, paddingFooter };
};
