import { SENTRY_LOG_LEVEL } from './constants';

let SENTRY;
let CURRENT_SENTRY_LOG_LEVEL;

const customLevels = {
  [SENTRY_LOG_LEVEL.error]: 0,
  [SENTRY_LOG_LEVEL.warning]: 1,
  [SENTRY_LOG_LEVEL.info]: 2,
  [SENTRY_LOG_LEVEL.debug]: 3,
};

export function setInstance(instance) {
  SENTRY = instance;
}

export default function getInstance() {
  return SENTRY;
}

export function setLogLevel(logLevel) {
  CURRENT_SENTRY_LOG_LEVEL = logLevel;
}

export function canLog(logLevel) {
  return customLevels[logLevel] <= customLevels[CURRENT_SENTRY_LOG_LEVEL];
}
