import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import pkg from '../package.json';
import LoadAppError from './components/LoadAppError';
import Root from './root.component';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId;
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement,
  React,
  ReactDOM,
  renderType: () => (domElement.hasChildNodes() ? 'hydrate' : 'render'),
  rootComponent: Root,
  errorBoundary: (error, info, props) => <LoadAppError error={error} {...props} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
