import { Dialog } from '@appscore/web-components';
import Modal from '@pedidosya/web-fenix/organisms/Modal';
import FenixModal from '@pedidosya/web-fenix/system/Modal';
import { useFenix } from '@pedidosya/web-fenix/contexts/FenixContext';
import Button from '@pedidosya/web-fenix/atoms/Button';
import FenixButton from '@pedidosya/web-fenix/system/Button';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import React from 'react';
import { dismissDialog } from '@app/providers/mainContext/actions/dismissDialog';
import { useDialogContext } from '@app/providers/mainContext/selectors/useAppDialog';
import { useAppDispatch } from '@app/providers/mainContext/selectors/useAppDispatch';

const BackendDrivenDialog = () => {
  const {
    fenixLayout = true,
    open,
    title,
    description,
    primary_action: primaryAction,
    secondary_action: secondaryAction,
    actionDefinitions,
    ...customProps
  } = useDialogContext();
  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);
  const dispatch = useAppDispatch();
  const {
    config: { revamp },
  } = useFenix();

  const { label: firstButtonText, actions: firstButtonActions } = primaryAction || {};
  const { label: secondButtonLabel, actions: secondButtonActions } = secondaryAction || {};

  const onFirstOptionClick = () => {
    dismissDialog(dispatch);
    executeBFFActions(firstButtonActions);
  };

  const onSecondOptionClick = () => {
    executeBFFActions(secondButtonActions);
  };

  if (revamp)
    return (
      <FenixModal
        open={open}
        title={title}
        text={description}
        primaryButton={
          <FenixButton
            fullWidth
            label={firstButtonText}
            size="large"
            onClick={onFirstOptionClick}
          />
        }
        secondaryButton={
          secondaryAction ? (
            <FenixButton
              fullWidth
              hierarchy="secondary"
              label={secondButtonLabel}
              size="large"
              onClick={onSecondOptionClick}
            />
          ) : null
        }
        {...customProps}
      />
    );

  if (fenixLayout)
    return (
      <Modal
        open={open}
        title={title}
        text={description}
        primaryButton={
          primaryAction ? (
            <Button fullWidth label={firstButtonText} size="large" onClick={onFirstOptionClick} />
          ) : null
        }
        secondaryButton={
          secondaryAction ? (
            <Button
              fullWidth
              hierarchy="secondary"
              label={secondButtonLabel}
              size="large"
              onClick={onSecondOptionClick}
            />
          ) : null
        }
      />
    );

  return (
    <Dialog
      open={open}
      title={title}
      text={description}
      firstButtonText={firstButtonText}
      onFirstOptionClick={onFirstOptionClick}
      secondButtonText={secondButtonLabel}
      onSecondOptionClick={onSecondOptionClick}
      {...customProps}
    />
  );
};

export default BackendDrivenDialog;
