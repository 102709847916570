import {
  BackendDrivenComponent,
  useBFFActionsExecutor,
  useBFFNavigate,
  useOnAppearActions,
} from '@checkout-ui/backend-driven';
import stringify from '@commons/utils/object/stringify';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { QUERY_PARAM } from '@commons/constants';
import ChangeBodyBackgroundColor from '@app/components/ChangeBodyBackgroundColor';
import { BrandErrorContainer } from '@app/components/Error';
import { useGoBack } from '@app/hooks/useGoBack';
import { useShowDialog } from '@app/hooks/useShowDialog';
import logger from '@app/logger';
import { BFF_ACTION_TYPES } from '@app/providers/mainContext/constants';
import { useSaveChallenge } from '@app/services/mutations';
import { useChallengeScreen } from '@app/services/queries';
import { useQueryParamValue } from '@app/utils/domRouter';

import styled from 'styled-components';
import TitleM from '@pedidosya/web-fenix/system/TitleM';
import Input from '@pedidosya/web-fenix/system/InputText';
import Footer from '@pedidosya/web-fenix/system/Footer';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';

import messages from './messages';
import { useDispatchDeviceEvents } from '@app/hooks/useDispatchDeviceEvents';

import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { CHALLENGE_TYPES } from '../../constants';
import { ERROR_CODE_TRACKING, EVENT_NAME_TRACKING, actionTracking } from '@app/utils/tracking';
import useOnce from '@app/hooks/useOnce';
import { publishChangeKeyboardBehaviorEvents } from '@app/utils/changeKeyboardBehaviorEvents';
import { useHeaderPaddingTop } from '@app/hooks/useHeaderPaddingTop';
import { useFooterBottomOffset } from '@app/hooks/useFooterBottomOffset';

import RevampedHeader from './components/RevampedHeader';

const CHALLENGE_TYPE_TIGO_OTP = 'TIGO_OTP';
const OTP_LENGTH = 6;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme, paddingTop }) => `${theme.space(paddingTop)} !important`};
  height: 100dvh;
`;

const StyledBackendDrivenComponent = styled(BackendDrivenComponent)`
  flex: 1;
`;

// Limits the value to OTP_LENGTH
const truncateValue = (val) => {
  const numericValue = val.replace(/\D/g, '');
  return numericValue.slice(0, OTP_LENGTH);
};

const Text = ({ title, subtitle }) => <TitleM descriptionValue={subtitle} titleValue={title} />;

const InputContainer = styled.div`
  padding-left: ${({ theme }) => theme.space('spacing-layout-medium')};
  padding-right: ${({ theme }) => theme.space('spacing-layout-medium')};
  padding-top: ${({ theme }) => theme.space('spacing-layout-medium')};
  flex-grow: 1;
`;

const StyledInput = styled(Input)`
  font-size: 16px !important;
`;

const InputBlock = ({ value, onChange, label }) => {
  return (
    <InputContainer>
      <StyledInput
        label={label}
        value={value}
        onChange={onChange}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
      />
    </InputContainer>
  );
};

const FooterStyled = styled(Footer)`
  position: sticky;
  bottom: ${({ bottomOffset }) => bottomOffset};
  padding-bottom: ${({ paddingFooter }) => paddingFooter};
`;

const RevampedTigoOTPChallenge = () => {
  // Keep this useEffect at the beginning to avoid scrolling in iOS devices.
  useOnce(() => publishChangeKeyboardBehaviorEvents());

  useDispatchDeviceEvents();

  const { navigate } = useBFFNavigate();
  const { goBack } = useGoBack();
  const { formatMessage } = useIntl();

  const { paddingTop } = useHeaderPaddingTop();
  const { bottomOffset, paddingFooter } = useFooterBottomOffset();

  const challengeId = useQueryParamValue(QUERY_PARAM.CHALLENGE_ID);

  const { data, error, isLoading, refetch } = useChallengeScreen(
    CHALLENGE_TYPE_TIGO_OTP,
    challengeId,
  );

  const onAppearPageActions = data?.on_appear?.actions;

  useOnAppearActions(onAppearPageActions);

  const [otpValue, setOtpValue] = useState('');

  const {
    isLoading: submittingChallenge,
    isSuccess: submitSuccessChallenge,
    reset: resetSubmitChallenge,
    mutate: submitChallenge,
  } = useSaveChallenge(CHALLENGE_TYPE_TIGO_OTP);

  const { showDialog: showErrorDialog } = useShowDialog();

  const onSubmitError = () => {
    resetSubmitChallenge();
    const dismissErrorDialogAction = {
      type: BFF_ACTION_TYPES.DISMISS_DIALOG,
    };
    showErrorDialog({
      fenixLayout: true,
      title: formatMessage(messages.submitErrorTitle),
      description: formatMessage(messages.submitErrorDescription),
      primary_action: {
        label: formatMessage(messages.submitErrorActionLabel),
        actions: [dismissErrorDialogAction],
      },
    });
  };

  const onSubmit = (payload) => {
    logger.info(
      '[REVAMP_TIGO_OTP_CHALLENGE][OTP_SAVE_INTENT]',
      'Trying to save OTP',
      stringify(payload),
    );
    submitChallenge(
      { otp_value: otpValue, ...payload },
      {
        onSuccess: (data) => {
          const actions = data?.actions;
          if (actions?.length) {
            logger.info(
              '[REVAMP_TIGO_OTP_CHALLENGE][OTP_SAVE_SUCCESS]',
              'OTP was saved successfully',
              stringify(actions),
            );
            executeBFFActions(actions);
          } else {
            executeBFFActions(
              actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
                challengeType: CHALLENGE_TYPES.TIGO_OTP.toLowerCase(),
                errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
              }),
            );
            logger.error(
              '[REVAMP_TIGO_OTP_CHALLENGE][OTP_SAVE_NO_ACTION]',
              'Submit Tigo OTP response do not have actions',
            );
            onSubmitError();
          }
        },
        onError: (error) => {
          executeBFFActions(
            actionTracking(EVENT_NAME_TRACKING.CHALLENGE_FAILED, {
              challengeType: CHALLENGE_TYPES.TIGO_OTP.toLowerCase(),
              errorCode: ERROR_CODE_TRACKING.SUBMIT_ERROR,
            }),
          );
          logger.error(
            '[REVAMP_TIGO_OTP_CHALLENGE][OTP_SAVE_ERROR]',
            'Fail saving OTP:',
            error.message,
          );
          onSubmitError();
        },
      },
    );
  };

  const actionDefinitions = {
    SUBMIT_TIGO_OTP_CHALLENGE: onSubmit,
    [BFF_ACTION_TYPES.NAVIGATE]: (payload) => {
      // NOTE: replace navigation in order to prevent user going back to previous submitted challenges
      navigate(payload, { replace: true });
    },
  };

  const { executeBFFActions } = useBFFActionsExecutor(actionDefinitions);

  const isDisabled =
    otpValue.length !== OTP_LENGTH || submittingChallenge || submitSuccessChallenge;

  const componentResolvers = {
    TITLE: ({ data: { title, subtitle } }) => ({
      component: Text,
      props: { title, subtitle },
    }),
    INPUT: ({ data: { label } }) => ({
      component: InputBlock,
      props: {
        value: otpValue,
        onChange: (v) => setOtpValue(truncateValue(v.target.value)),
        label,
      },
    }),
    FOOTER: ({ data: { footer_type: footerType } }) => ({
      component: FooterStyled,
      props: {
        paddingFooter,
        bottomOffset,
        footerType: (
          <BackendDrivenComponent
            componentResolvers={componentResolvers}
            components={[footerType]}
          />
        ),
        isSticky: true,
        isShadowVisible: false,
      },
    }),
    FOOTER_BUTTON: () => ({
      component: ({ children }) => <FooterButton>{children.props.children}</FooterButton>,
    }),
    BUTTON: ({ data: { text, style, size, actions } }) => ({
      component: FooterButton.Button,
      props: {
        label: text,
        hierarchy: style?.toLowerCase(),
        fullWidth: true,
        size,
        onClick: () => executeBFFActions(actions),
        state: isDisabled ? 'disabled' : 'enabled',
      },
    }),
  };

  if (isLoading) return <PeyaLoader position="center" />;
  if (error) {
    return (
      <BrandErrorContainer
        fenixLayout
        onPrimaryAction={refetch}
        secondaryLabel="Volver"
        onSecondaryAction={() => goBack()}
        errorCode={error.response?.status}
      />
    );
  }

  const header = {
    ...data?.bar,
    executeBFFActions,
  };

  const components = data?.components;

  return (
    <SectionContainer paddingTop={paddingTop}>
      <RevampedHeader {...header} />
      <ChangeBodyBackgroundColor color="white" />
      <StyledBackendDrivenComponent
        components={components}
        componentResolvers={componentResolvers}
      />
    </SectionContainer>
  );
};

export default RevampedTigoOTPChallenge;
