import { useEffect, useState } from 'react';
import { useHasNotch } from './useHasNotch';

const PADDING = {
  WITH_NOTCH: 'spacing-layout-6xlarge',
  WITHOUT_NOTCH: 'spacing-layout-xsmall',
};

export const useHeaderPaddingTop = () => {
  const [paddingTop, setPaddingTop] = useState();
  const { hasNotch } = useHasNotch();

  useEffect(() => {
    setPaddingTop(hasNotch ? PADDING.WITH_NOTCH : PADDING.WITHOUT_NOTCH);
  }, [hasNotch]);

  return { paddingTop };
};
